<script>
import { usePageField } from '@voix/composables/usePageField'
import smoothReflow from 'vue-smooth-reflow'
import { mapActions, mapState } from 'pinia'
import { usePageStore } from '@voix/store/pageStore'
import { useI18n } from 'vue-i18n'

import VoixScroller from '@voix/components/chrome/VoixScroller'
import { useNavigationStore } from '../../store/navigationStore'

import IconHamburgerMenu from '@/components/icons/IconsHamburger'
import IconAdd from '@/components/icons/IconsAdd'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  name: 'NavigationHeader',

  components: {
    VoixScroller,
    IconHamburgerMenu,
    IconAdd,
  },

  mixins: [smoothReflow],

  inject: ['currentResort', 'resorts', 'brands', 'accentColor'],

  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      forceShowScrollBooking: false,
      showOurResorts: false,
      referenceRect: { x: 0, y: 0, width: 0, height: 0 },
      subMenuActive: false,
      activeMenuItem: { id: null, children: [] },
      showingMobileSubmenu: null,
      showMobileBookingWidget: false,
      timeout: null,
      openLanguageSelect: false,
      t: useI18n(),
      topDistance: 0,
      hideBookNow: false,
      hideNavigation: false,
    }
  },

  computed: {
    ...mapState(useNavigationStore, ['mobileMenuOpen', 'breakpoint']),
    ...mapState(useBookingWidgetStore, ['showBookingModal']),
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    faqLink() {
      if (this.currentPage.language_code === 'es')
        return this.currentResort.es_faq_link
      else
        return this.currentResort.faq_link
    },
    alphabetizedResorts() {
      const resorts = JSON.parse(JSON.stringify(this.resorts))
      return resorts.sort((a, b) => {
        if (a.name < b.name)
          return -1
        if (a.name > b.name)
          return 1
        return 0
      })
    },
    subMenuStyle() {
      return {
        top: `${this.referenceRect.y}px`,
        left: `${this.referenceRect.x}px`,
      }
    },
    subMenuFullWidthStyle() {
      return { top: `${this.referenceRect.y}px` }
    },
    subMenuFullWidthEnabled() {
      return this.config.subMenuFullWidth?.enabled
    },
    subMenuFullWidthDisplay() {
      return this.config.subMenuFullWidth?.menuItems.includes(this.activeMenuItem?.name?.toLowerCase())
    },
    dominicanRepublicSubMenuItemList() {
      return this.subMenuItemList['DOMINICAN REPUBLIC'] || this.subMenuItemList['REPÚBLICA DOMINICANA']
    },
    subMenuItemList() {
      const lists = {}
      let currentList = ''

      this.currentResort.menu?.menu_items.forEach((item) => {
        if (item.children.length > 0 && item.name.toLowerCase().includes('resorts')) {
          item.children.forEach((child) => {
            if (child.permalink === '#') {
              lists[child.name] = []
              lists[child.name].push(child)
              currentList = child.name
            }
            else {
              lists[currentList].push(child)
            }
          })
        }
      })
      return lists
    },
    showSubMenu() {
      return this.subMenuActive
        && (
          this.activeMenuItem.children.length > 0
          || this.activeMenuItem.permalink === '#resort-dropdown'
          || this.activeMenuItem.permalink === '/all-inclusive-resorts'
        )
    },
    customButton() {
      if (!Object.hasOwnProperty.call(this.config, 'customButton'))
        return false

      return this.config.customButton !== false
    },
    showBooking() {
      return this.config.booking !== false && this.hideBookNow.value === false
    },
    showScrollBooking() {
      return this.config.scrollBooking !== false && this.hideBookNow.value === false
    },
    hideMobileBooking() {
      return this.config.hideMobileBooking === true || this.hideBookNow.value === true
    },
    myTripsLinkUrl() {
      let link = 'https://be.synxis.com/signIn?configcode=PlayaWebsite&&level=hotel&locale=en-US&nocache=true&themecode=PlayaWebsite'
      if (this.currentResort.booking_settings.synxis_hotel_num && this.currentResort.booking_settings.synxis_hotel_num) {
        const resortId = this.currentResort.booking_settings.synxis_hotel_num
        const locale = this.currentPage?.langauge_code === 'en' ? 'en-US' : 'es-MX'

        link = `https://be.synxis.com/signIn?configcode=PlayaWebsite&hotel=${resortId}&level=hotel&locale=${locale}&nocache=true&themecode=PlayaWebsite`
      }

      return this.addOneTrustCrossDomainParams(link)
    },
    logoWidth() {
      if (this.config.shiftOnScroll?.enabled && this.config.shiftOnScroll?.logoWidth && this.topDistance > this.config.shiftOnScroll?.offset)
        return this.config.shiftOnScroll.logoWidth

      return this.config.logoWidth || 145
    },
    textClass() {
      if (this.config.scrollTriggerDistance && this.config.scrollTextClass && this.topDistance > this.config.scrollTriggerDistance)
        return this.config.scrollTextClass

      return this.config.textClass ? this.config.textClass : 'text-gray-600'
    },
    topBarTextClass() {
      if (this.config?.darkTopBar)
        return 'text-white'

      return this.textClass
    },
    backgroundClass() {
      if (this.config.scrollTriggerDistance && this.config.scrollBackgroundClass && this.topDistance > this.config.scrollTriggerDistance)
        return this.config.scrollBackgroundClass

      return this.config.backgroundClass ? this.config.backgroundClass : 'bg-white'
    },
  },

  mounted() {
    this.$smoothReflow({
      el: this.$refs['navigation-submenu'],
    })
    this.calculateNavigationSpacer()

    window.addEventListener('resize', () => {
      this.calculateNavigationSpacer()
    })

    if (this.config.scrollTriggerDistance || this.config.shiftOnScroll?.enabled) {
      window.addEventListener('scroll', () => {
        this.topDistance = window.scrollY

        if (this.forceShowScrollBooking && this.topDistance > this.config.scrollTriggerDistance)
          this.forceShowScrollBooking = false
      })
    }
  },
  created() {
    if (this.config.showScrollBookingOnLoad)
      this.forceShowScrollBooking = true

    const pageField = usePageField()
    this.hideBookNow = pageField.get('hideBookNow', false)
    this.hideNavigation = pageField.get('hideNavigation', false)
  },
  methods: {
    ...mapActions(useNavigationStore, ['toggleMobileMenu']),
    ...mapActions(useBookingWidgetStore, ['toggleBookingModal']),

    toggleBookingWidget() {
      if (this.config.scrollBooking) {
        this.forceShowScrollBooking = !this.forceShowScrollBooking
        return
      }

      if (this.showBookingModal)
        this.toggleBookingModal()
      else
        this.toggleBookingModal('navigation-header')
    },
    requestShowSubmenu(item, el) {
      this.referenceRect = el.getBoundingClientRect()
      this.subMenuActive = true
      this.activeMenuItem = item
    },
    requestHideSubmenu() {
      this.subMenuActive = false
    },
    showMobileSubmenu(key) {
      if (this.showingMobileSubmenu === key)
        this.showingMobileSubmenu = null
      else
        this.showingMobileSubmenu = key
    },
    switchLanguage(url) {
      window.location = url
    },
    calculateNavigationSpacer() {
      const spacer = document.getElementById('navigation-spacer')
      if (spacer && this.$refs.navigationEl)
        spacer.style.height = `${this.$refs.navigationEl.clientHeight}px`
    },
    // onetrust
    addOneTrustCrossDomainParams(link) {
      if (typeof OneTrust !== 'undefined' && OneTrust && OneTrust.IsAlertBoxClosed()) {
        const _cookieParams = {
          USPrivacy: 'usprivacy',
          tcString: 'eupubconsent-v2',
          addtlString: 'OTAdditionalConsentString',
        }
        const _OptanonConsentParams = {
          groups: 'groups',
          consentedDate: 'datestamp',
        }
        const consentCookie = new URLSearchParams(this.getCookie('OptanonConsent'))
        const externalConsentParams = new URLSearchParams()
        Object.entries(_cookieParams).forEach((e) => {
          const [param, cookieName] = e
          if (link.match(param) == null) {
            externalConsentParams.set(param, this.getCookie(cookieName))
          }
        })
        Object.entries(_OptanonConsentParams).forEach((e) => {
          const [param, property] = e
          if (link.match(param) == null) {
            externalConsentParams.set(param, consentCookie.get(property))
          }
        })
        if (externalConsentParams.size) {
          link = `${link}&${externalConsentParams.toString()}`
        }
      }
      return link
    },
    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
  },
}
</script>

<template>
  <div v-if="currentResort && !hideNavigation.value" ref="navigationEl">
    <ClientOnly>
      <OurResorts v-if="config.allResorts" :show="showOurResorts" @hide="() => showOurResorts = false" />
    </ClientOnly>

    <div v-if="!config.noSpacer" id="navigation-spacer" class="hidden lg:block h-[160px]" />

    <!-- Desktop Nav -->
    <div
      class="hidden lg:flex items-center fixed z-[8000] top-0 left-0 right-0 duration-300 ease-out"
      :class="[
        config.containerClasses, backgroundClass,
        { '-translate-y-12': topDistance > config.shiftOnScroll?.offset && config.shiftOnScroll?.enabled }]"
    >
      <div
        v-if="config.backdropBlur"
        class="absolute inset-0 z-[-1] w-full bg-gradient-to-b duration-[2s] from-black/80 to-black/10" :class="{
          'backdrop-blur-xl': topDistance > config.scrollTriggerDistance,

        }"
      />

      <div class="w-full">
        <div class="relative w-full">
          <div
            class="absolute z-10 top-0 left-0 bottom-0 flex" :class="{
              'items-center': !config.shiftOnScroll?.enabled || topDistance <= config.shiftOnScroll?.offset,
              'items-end pb-2': config.shiftOnScroll?.enabled && topDistance > config.shiftOnScroll?.offset,
            }"
          >
            <div
              v-if="!config.stacked && !config.relativeLogo"
              class="px-8 py-2 pointer-events-none flex" :class="{
                'justify-start': config.logoPosition === 'left',
                'justify-center': config.logoPosition === 'center',
                'justify-end': config.logoPosition === 'right',
              }"
            >
              <NuxtLink :to="`/${currentResort?.url_partial || ''}`" class="pointer-events-auto">
                <slot name="logo" :distance-triggered="topDistance > config.scrollTriggerDistance">
                  <img :width="logoWidth" :src="`${currentResort.logo}`" class="duration-300" :alt="currentResort.name">
                </slot>
              </NuxtLink>
            </div>
          </div>

          <div
            class="grid grid-cols-3 justify-center items-start w-full relative py-3 "
            :class="[{
              'border-b border-gray-200': config.stacked,
            }, config.languageSelectorBackground ? config.languageSelectorBackground : 'bg-transparent']"
          >
            <div v-if="config.paper" class="paper absolute inset-0 z-0 pointer-events-none" />
            <div v-if="config.hyattAnimationBlue" class="absolute overflow-hidden top-0 left-0 right-0 bottom-0 pointer-events-none ">
              <BackgroundElementsSwooshUpRightBlues w="340" h="340" />
            </div>
            <div v-if="config.hyattAnimationPink" class="absolute overflow-hidden top-0 left-0 right-0 bottom-0 pointer-events-none ">
              <BackgroundElementsSwooshUpRightPinks w="340" h="340" />
            </div>

            <div
              :class="{
                'justify-self-start col-span-2 px-8': config.logoPosition === 'left',
                'justify-self-center col-start-2': config.logoPosition === 'center',
              }"
            >
              <NuxtLink v-if="config.stacked" :to="`/${currentResort.url_partial}`" class="pointer-events-auto">
                <img :width="config.logoWidth || 145" :src="`${currentResort.logo}`" :alt="currentResort.name">
              </NuxtLink>
            </div>

            <div class="col-start-3 relative z-10 justify-self-end flex items-center space-x-4 pr-8">
              <div
                v-if="currentResort.show_my_trip_link"
                class="white cursor-pointer uppercase text-sm"
              >
                <NuxtLink :class="[topBarTextClass]" :to="myTripsLinkUrl">
                  {{ $t("my-trips") }}
                </NuxtLink>
              </div>
              <div v-if="currentPage.localizations.length > 0" class="cursor-pointer uppercase text-sm">
                <span
                  class="dvs-cursor-pointer" :class="[config.languageSelectorText ? config.languageSelectorText : 'text-gray-600']" @blur="openLanguageSelect = false"
                  @click.prevent="openLanguageSelect = !openLanguageSelect"
                >
                  {{ $t(currentPage.language_code) }}
                  <Icon name="heroicons:chevron-down-20-solid" class="inline -mt-1" :class="[config.languageSelectorText ? config.languageSelectorText : 'text-gray-600']" :w="12" :h="12" />
                </span>
                <div
                  v-if="openLanguageSelect" class="fixed py-z  z-20 mt-2 mr-2"
                  :class="[
                    config.languageSelectorBg ? config.languageSelectorBg : 'bg-grey-lightest',
                  ]
                  "
                >
                  <NuxtLink
                    v-for="localization in currentPage.localizations"
                    :key="localization.path"
                    class="block px-3 py-2 small dvs-cursor-pointer text-xs"
                    :to="localization.path"
                  >
                    {{ $t(localization.language_code) }}
                  </NuxtLink>
                </div>
              </div>
              <div
                v-if="faqLink"
                class="white cursor-pointer uppercase text-sm"
              >
                <NuxtLink
                  :class="[topBarTextClass]"
                  :to="faqLink"
                >
                  FAQ
                </NuxtLink>
              </div>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div>
              <div
                v-if="!config.stacked && config.relativeLogo"
                class="pl-4 py-2 pointer-events-none flex " :class="{
                  'justify-start': config.logoPosition === 'left',
                  'justify-center': config.logoPosition === 'center',
                  'justify-end': config.logoPosition === 'right',
                }"
              >
                <NuxtLink :to="`/${currentResort.url_partial}`" class="pointer-events-auto">
                  <slot name="logo" :distance-triggered="topDistance > config.scrollTriggerDistance">
                    <img class="max-h-[80px]" :src="`${currentResort.logo}`" :alt="currentResort.name">
                  </slot>
                </NuxtLink>
              </div>
            </div>
            <div class="flex-1">
              <div
                v-if="currentResort.menu !== null"
                class="relative z-60 flex items-center w-full py-1"
                :class="{
                  'justify-center': config.menuPosition === 'center',
                  'justify-end': config.menuPosition === 'right',
                  'border-b border-gray-200': config.stacked,
                }"
              >
                <div
                  class="flex w-full" :class="{
                    'justify-center': config.menuPosition === 'center',
                    'justify-end': config.menuPosition === 'right',
                  }"
                >
                  <!-- Main Menu -->
                  <ul
                    v-cloak class="flex items-center uppercase space-x-4 xl:space-x-6 p-4" :class="{
                      'text-xs 2xl:text-sm': !config.stacked,
                      'text-sm lg:text-base': config.stacked,
                      'justify-self-center': config.menuPosition === 'center',
                    }"
                  >
                    <li
                      v-for="(item, menuKey) in currentResort.menu?.menu_items"
                      :key="menuKey"
                      @mouseenter="requestShowSubmenu(item, $event.target)"
                      @mouseleave="requestHideSubmenu"
                    >
                      <NuxtLink
                        v-if="item.permalink"
                        :to="item.permalink" class="flex items-center"
                        :class="[textClass]"
                      >
                        <span class="whitespace-nowrap overflow-hidden text-ellipsis">{{ item.name }}</span>
                        <Icon
                          v-if="
                            item.children.length > 0
                              || item.name === 'Our Resorts'
                              || item.name === 'resort'
                          "
                          name="heroicons:chevron-down-20-solid"
                          class="hidden xl:inline -mt-1"
                          :class="[textClass]"
                          :w="12"
                          :h="12"
                        />
                      </NuxtLink>
                      <button
                        v-else
                        class="flex items-center"
                        :class="[textClass]"
                      >
                        <span class="uppercase whitespace-nowrap overflow-hidden text-ellipsis">{{ item.name }}</span>
                        <Icon
                          v-if="
                            item.children.length > 0
                              || item.name === 'Our Resorts'
                              || item.name === 'resort'
                          "
                          name="heroicons:chevron-down-20-solid"
                          class="hidden xl:inline -mt-1"
                          :class="[textClass]"
                          :w="12"
                          :h="12"
                        />
                      </button>
                      <div v-if="item.children.length > 0" class="submenu hidden">
                        <div class="text-3xl uppercase title font-thin mb-4 text-center ">
                          {{ item.name }}
                        </div>
                        <ul class="text-center">
                          <li
                            v-for="(menuItem, itemKey) in item.children"
                            :key="itemKey"
                            class="mb-2"
                          >
                            <NuxtLink :to="menuItem.permalink" class="text-gray-600 mx-4 normal-case">
                              {{
                                menuItem.name
                              }}
                            </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li v-if="config.menuPosition !== 'center' && showBooking">
                      <div
                        :remote-enabled="true"
                        class="flex items-stretch pr-4"
                      >
                        <button
                          class="py-3 2xl:py-4 px-5 2xl:px-8 text-sm 2xl:text-base font-bold uppercase"
                          :class="[
                            config.bookNowBackground ? config.bookNowBackground : 'bg-playa-action',
                            config.bookNowText ? config.bookNowText : 'text-white',
                            config.bookNowBorder ? config.bookNowBorder : 'border-transparent',
                          ]
                          " @click="toggleBookingWidget"
                        >
                          {{ $t("book-now") }}
                        </button>
                      </div>
                    </li>
                    <li v-if="customButton">
                      <slot />
                    </li>
                  </ul>

                  <div
                    v-if="showBooking && config.menuPosition === 'center'"
                    :remote-enabled="true"
                    class="flex items-stretch pr-4"
                  >
                    <button
                      class="py-5 px-8 text-base font-bold uppercase"
                      :class="[
                        config.bookNowBackground ? config.bookNowBackground : 'bg-playa-action',
                        config.bookNowText ? config.bookNowText : 'text-white',
                        config.bookNowBorder ? config.bookNowBorder : 'border-transparent',
                      ]
                      " @click="toggleBookingWidget"
                    >
                      {{ $t("book-now") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showBookingModal"
          class="relative px-8 z-50 flex justify-center inline-flight-booking w-full bg-black"
        >
          <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="toggleBookingWidget">
            Close
          </button>
          <FlightBookingForm />
        </div>
      </div>

      <div
        v-if="showScrollBooking && config.scrollTriggerDistance"
        class="absolute z-40 top-0 left-0 right-0 duration-200 ease-out" :class="[{
          '-translate-y-full': !forceShowScrollBooking,
          'translate-y-full': forceShowScrollBooking,
        }]"
      >
        <slot name="scrollContent">
          <div class="max-w-5xl mx-auto">
            <FlightBookingForm
              :class="{
                'translate-y-1': !currentResort || !currentResort?.booking_settings?.flights_enabled,
                '-translate-y-9': currentResort && currentResort?.booking_settings?.flights_enabled,
              }"
            />
          </div>
        </slot>
      </div>
    </div>

    <!-- SubMenu (Normal) -->
    <div
      v-if="!subMenuFullWidthEnabled || subMenuFullWidthEnabled && !subMenuFullWidthDisplay"
      class="fixed z-[8001] mt-4 pt-10 transition-all duration-250 ease-out fixed ml-10 -translate-x-[50%]"
      :class="{
        'opacity-0 pointer-events-none ': !showSubMenu,
        'opacity-100 pointer-events-auto': showSubMenu,
      }"
      :style="subMenuStyle"
      @mouseenter="subMenuActive = true"
      @mouseleave="subMenuActive = false"
    >
      <div
        ref="navigation-submenu"
        class="shadow rounded p-8 submenu bg-white mt-2"
        style="min-width: 300px; transition: height 1s"
      >
        <div
          v-for="(item, menuKey) in currentResort.menu?.menu_items"
          :key="menuKey"
        >
          <div v-if="item.permalink === '#resort-dropdown'" :class="{ hidden: activeMenuItem.id !== item.id }">
            <div v-if="config.allResorts" style="min-width: 730px">
              <div class="text-3xl uppercase title font-thin mb-4 text-center">
                Our Resorts
              </div>
              <div class="flex justify-center">
                <div class="mx-8 w-1/2 text-right">
                  <h2 class="font-thin mb-4">
                    Dominican Republic
                  </h2>
                  <ul>
                    <template v-for="(resort, key) in resorts" :key="key">
                      <template v-if="resort.country === 'Dominican Republic'">
                        <li class="mb-2">
                          <a
                            class="text-gray-600"
                            :href="resort.permalink"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                  <h2 class="font-thin mb-4">
                    Jamaica
                  </h2>
                  <ul>
                    <template v-for="(resort, key) in resorts" :key="key">
                      <template v-if="resort.country === 'Jamaica'">
                        <li class="mb-2">
                          <a
                            class="text-gray-600"
                            :href="resort.permalink"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>

                <div class="mx-8 text-left w-1/2">
                  <h2 class="font-thin mb-4">
                    Mexico
                  </h2>
                  <ul>
                    <template v-for="(resort, key) in resorts" :key="key">
                      <template v-if="resort.country === 'Mexico'">
                        <li class="mb-2">
                          <a
                            class="text-gray-600"
                            :href="resort.permalink"
                          >
                            {{ resort.name }}
                          </a>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div v-if="item.permalink === '/all-inclusive-resorts'" :class="{ hidden: activeMenuItem.id !== item.id }">
            <div style="width: 785px">
              <div class="text-3xl uppercase title font-thin mb-4 text-center">
                Our Brands
              </div>
              <div class="flex justify-start items-center flex-wrap w-full">
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://www.resortsbyhyatt.com/"
                    class="zoom-emphasize"
                    aria-label="Hyatt Ziva"
                  >
                    <LazyLogosHyattZiva :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://www.resortsbyhyatt.com/"
                    class="zoom-emphasize"
                    aria-label="Hyatt Zilara"
                  >
                    <LazyLogosHyattZilara :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://www.playaresorts.com/turquoize-ziva-cancun/"
                    class="zoom-emphasize"
                    aria-label="Turquoize"
                  >
                    <LazyLogosTurquoize :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://hiltonbyplaya.com/"
                    class="zoom-emphasize"
                    aria-label="Hilton"
                  >
                    <LazyLogosHilton :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://sanctuarycapcana.com/"
                    class="zoom-emphasize"
                    aria-label="Sanctuary"
                  >
                    <LazyLogosSanctuary :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://www.jewelgrande.com/"
                    class="zoom-emphasize"
                    aria-label="Jewel Grande"
                  >
                    <LazyLogosJewelGrande :w="190" :h="100" />
                  </a>
                </div>
                <div class="w-1/3 my-4 px-4">
                  <a
                    href="https://alltrabyplaya.com/"
                    class="zoom-emphasize"
                    style="color: #55565a"
                    aria-label="Wyndham Alltra"
                  >
                    <LazyLogosWyndham class="w-48" style="height: 96px" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-if="item.children.length > 0" :class="{ hidden: activeMenuItem.id !== item.id }">
            <div class="flex justify-between items-center flex-wrap w-full">
              <div class="text-center w-full">
                <div
                  class="text-3xl uppercase title font-thin mb-4 text-center"
                >
                  {{ item.name }}
                </div>

                <ul>
                  <template
                    v-for="(child, childKey) in item.children"
                    :key="childKey"
                  >
                    <li
                      class="mb-2"
                    >
                      <NuxtLink
                        class="text-gray-600"
                        :to="child.permalink"
                      >
                        {{ child.name }}
                      </NuxtLink>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SubMenu (FullWidth) -->
    <div
      v-else
      class="fixed h-full w-full z-[8001] mt-4 pt-10 transition-all duration-250 ease-out"
      :class="{
        'opacity-0 pointer-events-none ': !showSubMenu,
        'opacity-100 pointer-events-auto': showSubMenu,
      }"
      :style="subMenuFullWidthStyle"
      @mouseenter="subMenuActive = true"
      @mouseleave="subMenuActive = false"
    >
      <div
        ref="navigation-submenu"
        class="shadow rounded p-8 submenu bg-white mt-0"
        style="transition: height 1s"
      >
        <div
          v-for="(item, menuKey) in currentResort.menu?.menu_items"
          :key="menuKey"
        >
          <div v-if="item.children.length > 0" :class="{ hidden: activeMenuItem.id !== item.id }">
            <div class="flex justify-between items-center flex-wrap w-full">
              <div class="text-center w-full">
                <div
                  class="text-3xl uppercase title font-thin mb-6 text-center"
                >
                  {{ item.name }}
                </div>

                <!-- All Inclusive Resorts -->
                <div class="grid grid-cols-3 gap-12 max-w-[800px] mx-auto">
                  <!-- Dominican Republic -->
                  <ul>
                    <template
                      v-for="(child, childKey) in dominicanRepublicSubMenuItemList"
                      :key="childKey"
                    >
                      <li
                        class="py-1 w-full text-left"
                        :class="{
                          'mb-2 border-b border-gray-600': child.permalink === '#',
                        }"
                      >
                        <NuxtLink
                          class="text-gray-600"
                          :to="child.permalink"
                        >
                          {{ child.name }}
                        </NuxtLink>
                      </li>
                    </template>
                  </ul>

                  <!-- Jamaica -->
                  <ul>
                    <template
                      v-for="(child, childKey) in subMenuItemList.JAMAICA"
                      :key="childKey"
                    >
                      <li
                        class="py-1 w-full text-left"
                        :class="{
                          'mb-2 border-b border-gray-600': child.permalink === '#',
                        }"
                      >
                        <NuxtLink
                          class="text-gray-600"
                          :to="child.permalink"
                        >
                          {{ child.name }}
                        </NuxtLink>
                      </li>
                    </template>
                  </ul>

                  <!-- Mexico -->
                  <ul>
                    <template
                      v-for="(child, childKey) in subMenuItemList.MEXICO"
                      :key="childKey"
                    >
                      <li
                        class="py-1 w-full text-left"
                        :class="{
                          'mb-2 border-b border-gray-600': child.permalink === '#',
                        }"
                      >
                        <NuxtLink
                          class="text-gray-600"
                          :to="child.permalink"
                        >
                          {{ child.name }}
                        </NuxtLink>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Nav -->
    <div
      class="lg:hidden fixed top-0 left-0 right-0 z-50"
      :class="[{ 'bg-white': !config.mobileMenuBackground }, config.mobileMenuBackground]"
    >
      <div
        class="flex md:flex lg:flex justify-between items-stretch border-b-grey border-b relative transition-all duration-500 ease-in-out"
        :class="{ 'translate-x-[80%]': mobileMenuOpen }"
      >
        <div
          class=" py-8 pl-4"
          :class="[{ 'text-gray-600': !config.mobileMenuForeground }, config.mobileMenuForeground]"
          @click="toggleMobileMenu"
        >
          <IconHamburgerMenu :w="25" :h="25" :fill="true" />
        </div>
        <div class="flex items-center mx-4">
          <a href="/">
            <slot name="mobilelogo">
              <img class="max-h-[72px]" :src="`${currentResort.logo}`" :alt="currentResort.name">
            </slot>
          </a>
        </div>
        <div class="flex items-stretch">
          <button
            v-if="!hideMobileBooking"
            class="btn btn-sm bg-playa-action text-white"
            :style="[config.mobileMenuBookingBackground, config.mobileMenuBookingForeground]"
            @click="showMobileBookingWidget = true"
          >
            {{ $t("book-now") }}
          </button>
        </div>

        <div v-if="customButton" class="flex items-stretch">
          <slot />
        </div>

        <Teleport v-if="showMobileBookingWidget" to="#portal-destination">
          <div

            class="fixed inset-0 px-8 z-[9999] flex justify-center items-start py-12"
          >
            <div class="absolute inset-0 bg-black opacity-80 pointer-cursor" :style="{ backgroundColor: accentColor.value }" @click="showMobileBookingWidget = false" />
            <button class="absolute top-0 right-0 text-xs uppercase m-4 text-gray-200" @click="showMobileBookingWidget = false">
              Close
            </button>
            <FlightBookingForm class="mt-10" />
          </div>
        </Teleport>
      </div>

      <ClientOnly>
        <Teleport to="#portal-mobile-menu">
          <div
            class="fixed top-0 bottom-0 left-0 w-full bg-playa-inverse transition-all duration-500 ease-in-out"
            style="z-index: 99999"
            :class="{ '-translate-x-full opacity-0': !mobileMenuOpen }"
          >
            <VoixScroller>
              <div class="px-12">
                <div class="text-playa-action m-4 flex items-center justify-between mb-8">
                  <slot name="mobilelogo">
                    <img :width="config.logoWidth || 145" :src="`${currentResort.logo}`" :alt="currentResort.name">
                  </slot>

                  <button class="p-4 pointer-events-auto" aria-label="Close Menu" @click="toggleMobileMenu">
                    <Icon name="heroicons:x-mark-20-solid" class="text-playa-action w-12 h-12" />
                  </button>
                </div>
                <!-- Phone call -->
                <div v-if="currentResort.phone_number" class="text-playa-action flex items-center justify-center mb-8">
                  <Icon name="heroicons:phone-20-solid" class="w-8 h-8" />
                  <a class="text-playa-action" :href="`tel:${currentResort.phone_number}`">
                    {{ $t('call') }}: {{ currentResort.phone_number }}
                  </a>
                </div>
                <ul v-if="currentResort.menu !== null" class="text-playa-action">
                  <li
                    v-for="(item, key) in currentResort.menu.menu_items"
                    :key="key"
                    class="my-4 pl-8"
                  >
                    <div class="flex items-center" @click="showMobileSubmenu(key)">
                      <IconAdd
                        v-if="
                          item.permalink === '#resort-dropdown'
                            || (item.children && item.children.length > 0)
                        "
                        style="margin-left: -25px"
                        :w="25"
                        :h="25"
                        :fill="false"
                      />
                      <a
                        v-if="item.permalink"
                        :href="item.permalink"
                        class="text-playa-action font-bold text-2xl"
                      >{{ item.name }}</a>
                      <button
                        v-else
                        :href="item.permalink"
                        class="text-playa-action font-bold text-2xl"
                      >
                        {{ item.name }}
                      </button>
                    </div>
                    <div v-if="item.children.length > 0 && showingMobileSubmenu === key">
                      <ul class="mt-4">
                        <li
                          v-for="(menuItem, itemKey) in item.children"
                          :key="itemKey"
                          class="mb-2"
                        >
                          <a v-if="item.permalink !== '#resort-dropdown'" :href="menuItem.permalink" class="text-playa-action">{{
                            menuItem.name
                          }}</a>
                          <span v-else class="text-playa-action">{{
                            menuItem.name
                          }}</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="item.permalink === '#resort-dropdown' && showingMobileSubmenu === key"
                      class="pr-8"
                    >
                      <div class="flex flex-col mb-8 mt-4">
                        <div class="mb-8">
                          <h4 class="font-bold mb-4 text-left uppercase">
                            Mexico
                          </h4>
                          <ul>
                            <template
                              v-for="(resort, resortsKey) in alphabetizedResorts"
                            >
                              <template v-if="resort.country === 'Mexico'">
                                <li :key="resortsKey" class="mb-2">
                                  <a
                                    class="text-playa-action"
                                    :href="resort.permalink"
                                  >
                                    {{ resort.name }}
                                  </a>
                                </li>
                              </template>
                            </template>
                          </ul>
                        </div>
                        <div class="mb-8">
                          <h4 class="font-bold mb-4 text-left uppercase">
                            Jamaica
                          </h4>
                          <ul>
                            <template
                              v-for="(resort, resortsKey) in alphabetizedResorts"
                            >
                              <template v-if="resort.country === 'Jamaica'">
                                <li :key="resortsKey" class="mb-2">
                                  <a
                                    class="text-playa-action"
                                    :href="resort.permalink"
                                  >
                                    {{ resort.name }}
                                  </a>
                                </li>
                              </template>
                            </template>
                          </ul>
                        </div>
                        <div class="mb-8">
                          <h4 class="font-bold mb-4 text-left uppercase">
                            Dominican Republic
                          </h4>
                          <ul>
                            <template
                              v-for="(resort, resortsKey) in alphabetizedResorts"
                            >
                              <template v-if="resort.country === 'Dominican Republic'">
                                <li :key="resortsKey" class="mb-2">
                                  <a
                                    class="text-playa-action"
                                    :href="resort.permalink"
                                  >
                                    {{ resort.name }}
                                  </a>
                                </li>
                              </template>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </VoixScroller>
          </div>
        </Teleport>
      </ClientOnly>
    </div>
    <div style="height: 80px" class="md:hidden" />
  </div>
</template>
